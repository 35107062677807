export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
          When clients engage with Space box, they can expect a full-service architectural experience. This means our team works closely with you from start to finish, from the spark of an idea to the full realization of your dream home.
          Our range of high-end service offerings include:
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <img src="../../img/Picture2.png"  src={`../../img/${d.icon}`} ></img>
                  {/* <i id="fontsize" className={d.icon}></i> */}

                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
