export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
            Team behind Space Box Design Studio
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-12 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <h5>{d.job}</h5>
                      <p>B.Arch, M.Plan(SPA-Delhi), RACA, AIIA, AITP, IGBC AP, ECBC TPA </p>
                      <br/>
                      <p>Ar. Supradeep P V K is the founder of Space Box Architecture Design Studio. He is the Principal Architect, Urban Planner, Interior Designer who had an experience of 5+ years and worked on more than 100 projects approximately. 

His design philosophy features an intelligent integration of architecture into the interior space. 

Specializing in various distinctive design projects and experiences offers our association a full range of design services for clients. </p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
