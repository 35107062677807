import Particles from "react-tsparticles"
export const Header = (props) => {
  return (
    <>
      
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container mycontainer'>
          <Particles
            id="tsparticles"
            options={{
                height:"100",
                background: {
                  color: {
                    value: "transparent",
                  },
                },
                fpsLimit: 20,
                interactivity: {
                  events: {
                    onClick: {
                      enable: false,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 1000,
                      duration: 10,
                      opacity: 1,
                      size: 40,
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 400,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ccc",
                  },
                  links: {
                    color: "#ccc",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 2,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 5,
                  },
                },
                detectRetina: false,
              }}
            />
            <div className='row rightcontenthead'>
              <div className='col-md-12 intro-text'>
              <div className="imagedetails">
              <img id="buildimg" src="../../img/introbg.png" alt="Italian Trulli"/>
              </div>
                <div className="headdetails">
                 <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <i><p>{props.data ? props.data.paragraph : 'Loading'}</p></i>
                <a
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn More
                </a>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}
